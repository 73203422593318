import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import { Blog } from '@/types';
import { SpeechRecognitionCreator } from '@/types';
import Directives from './directives';

declare global {
  interface Window {
    pageData?: {
      scrollY: number;
    };
    blogs: Blog[];
    webkitSpeechRecognition: SpeechRecognitionCreator;
    SpeechRecognition: SpeechRecognitionCreator;
  }
  interface Navigator {
    msMaxTouchPoints: number;
  }
}

window.blogs = [
  {
    id: 1,
    title: 'The Connection Between Oral Health and Overall Wellness',
    date: '02.09.2024',
    description:
      'Explore how maintaining good oral hygiene can impact overall health, including cardiovascular health, diabetes, and even mental health.',
    image: '/blog/blog1.png',
    sections: [
      {
        title: 'You Only Need to Brush Your Teeth Twice a Day',
        content:
          'Brushing your teeth twice a day is a good start, but it’s not always enough. The American Dental Association (ADA) recommends brushing your teeth for two minutes each time and using fluoride toothpaste. However, if you’re eating and drinking throughout the day, food particles and plaque are constantly building up. For optimal oral health, it’s essential to brush your teeth twice a day, but also to floss daily and use mouthwash to help clean those hard-to-reach areas. ',
      },
      {
        title: 'Sugar Causes Cavities',
        content:
          'While sugar does play a significant role in the development of cavities, it’s not the sugar itself but the bacteria in your mouth that feed on it. These bacteria produce acids that erode tooth enamel, leading to cavities. Therefore, it’s not just the amount of sugar you consume but how often you expose your teeth to sugary foods and drinks. Maintaining good oral hygiene by brushing and flossing can help reduce the risk of cavities, even if you enjoy an occasional sweet treat.',
      },
    ],
  },
  {
    id: 2,
    title: 'Top 5 Common Dental Myths Debunked',
    date: '02.09.2024',
    description:
      'Address and clarify common misconceptions about dental care, such as the effectiveness of brushing alone or the impact of sugar on teeth.',
    image: '/blog/blog2.png',
    sections: [
      {
        title: 'You Only Need to Brush Your Teeth Twice a Day',
        content:
          'Brushing your teeth twice a day is a good start, but it’s not always enough. The American Dental Association (ADA) recommends brushing your teeth for two minutes each time and using fluoride toothpaste. However, if you’re eating and drinking throughout the day, food particles and plaque are constantly building up. For optimal oral health, it’s essential to brush your teeth twice a day, but also to floss daily and use mouthwash to help clean those hard-to-reach areas. ',
      },
      {
        title: 'Sugar Causes Cavities',
        content:
          'While sugar does play a significant role in the development of cavities, it’s not the sugar itself but the bacteria in your mouth that feed on it. These bacteria produce acids that erode tooth enamel, leading to cavities. Therefore, it’s not just the amount of sugar you consume but how often you expose your teeth to sugary foods and drinks. Maintaining good oral hygiene by brushing and flossing can help reduce the risk of cavities, even if you enjoy an occasional sweet treat.',
      },
    ],
  },
  {
    id: 3,
    title: 'A Guide to Preventing Dental Emergencies',
    date: '02.09.2024',
    description:
      'Offer tips on how to prevent common dental issues like chipped teeth, gum infections, and toothaches, and what to do if an emergency occurs.',
    image: '/blog/blog1.png',
    sections: [
      {
        title: 'You Only Need to Brush Your Teeth Twice a Day',
        content:
          'Brushing your teeth twice a day is a good start, but it’s not always enough. The American Dental Association (ADA) recommends brushing your teeth for two minutes each time and using fluoride toothpaste. However, if you’re eating and drinking throughout the day, food particles and plaque are constantly building up. For optimal oral health, it’s essential to brush your teeth twice a day, but also to floss daily and use mouthwash to help clean those hard-to-reach areas. ',
      },
      {
        title: 'Sugar Causes Cavities',
        content:
          'While sugar does play a significant role in the development of cavities, it’s not the sugar itself but the bacteria in your mouth that feed on it. These bacteria produce acids that erode tooth enamel, leading to cavities. Therefore, it’s not just the amount of sugar you consume but how often you expose your teeth to sugary foods and drinks. Maintaining good oral hygiene by brushing and flossing can help reduce the risk of cavities, even if you enjoy an occasional sweet treat.',
      },
    ],
  },
  {
    id: 4,
    title: 'The Connection Between Oral Health and Overall Wellness',
    date: '02.09.2024',
    description:
      'Explore how maintaining good oral hygiene can impact overall health, including cardiovascular health, diabetes, and even mental health.',
    image: '/blog/blog1.png',
    sections: [
      {
        title: 'You Only Need to Brush Your Teeth Twice a Day',
        content:
          'Brushing your teeth twice a day is a good start, but it’s not always enough. The American Dental Association (ADA) recommends brushing your teeth for two minutes each time and using fluoride toothpaste. However, if you’re eating and drinking throughout the day, food particles and plaque are constantly building up. For optimal oral health, it’s essential to brush your teeth twice a day, but also to floss daily and use mouthwash to help clean those hard-to-reach areas. ',
      },
      {
        title: 'Sugar Causes Cavities',
        content:
          'While sugar does play a significant role in the development of cavities, it’s not the sugar itself but the bacteria in your mouth that feed on it. These bacteria produce acids that erode tooth enamel, leading to cavities. Therefore, it’s not just the amount of sugar you consume but how often you expose your teeth to sugary foods and drinks. Maintaining good oral hygiene by brushing and flossing can help reduce the risk of cavities, even if you enjoy an occasional sweet treat.',
      },
    ],
  },
  {
    id: 5,
    title: 'Top 5 Common Dental Myths Debunked',
    date: '02.09.2024',
    description:
      'Address and clarify common misconceptions about dental care, such as the effectiveness of brushing alone or the impact of sugar on teeth.',
    image: '/blog/blog2.png',
    sections: [
      {
        title: 'You Only Need to Brush Your Teeth Twice a Day',
        content:
          'Brushing your teeth twice a day is a good start, but it’s not always enough. The American Dental Association (ADA) recommends brushing your teeth for two minutes each time and using fluoride toothpaste. However, if you’re eating and drinking throughout the day, food particles and plaque are constantly building up. For optimal oral health, it’s essential to brush your teeth twice a day, but also to floss daily and use mouthwash to help clean those hard-to-reach areas. ',
      },
      {
        title: 'Sugar Causes Cavities',
        content:
          'While sugar does play a significant role in the development of cavities, it’s not the sugar itself but the bacteria in your mouth that feed on it. These bacteria produce acids that erode tooth enamel, leading to cavities. Therefore, it’s not just the amount of sugar you consume but how often you expose your teeth to sugary foods and drinks. Maintaining good oral hygiene by brushing and flossing can help reduce the risk of cavities, even if you enjoy an occasional sweet treat.',
      },
    ],
  },
  {
    id: 6,
    title: 'A Guide to Preventing Dental Emergencies',
    date: '02.09.2024',
    description:
      'Offer tips on how to prevent common dental issues like chipped teeth, gum infections, and toothaches, and what to do if an emergency occurs.',
    image: '/blog/blog1.png',
    sections: [
      {
        title: 'You Only Need to Brush Your Teeth Twice a Day',
        content:
          'Brushing your teeth twice a day is a good start, but it’s not always enough. The American Dental Association (ADA) recommends brushing your teeth for two minutes each time and using fluoride toothpaste. However, if you’re eating and drinking throughout the day, food particles and plaque are constantly building up. For optimal oral health, it’s essential to brush your teeth twice a day, but also to floss daily and use mouthwash to help clean those hard-to-reach areas. ',
      },
      {
        title: 'Sugar Causes Cavities',
        content:
          'While sugar does play a significant role in the development of cavities, it’s not the sugar itself but the bacteria in your mouth that feed on it. These bacteria produce acids that erode tooth enamel, leading to cavities. Therefore, it’s not just the amount of sugar you consume but how often you expose your teeth to sugary foods and drinks. Maintaining good oral hygiene by brushing and flossing can help reduce the risk of cavities, even if you enjoy an occasional sweet treat.',
      },
    ],
  },
  {
    id: 7,
    title: 'The Connection Between Oral Health and Overall Wellness',
    date: '02.09.2024',
    description:
      'Explore how maintaining good oral hygiene can impact overall health, including cardiovascular health, diabetes, and even mental health.',
    image: '/blog/blog1.png',
    sections: [
      {
        title: 'You Only Need to Brush Your Teeth Twice a Day',
        content:
          'Brushing your teeth twice a day is a good start, but it’s not always enough. The American Dental Association (ADA) recommends brushing your teeth for two minutes each time and using fluoride toothpaste. However, if you’re eating and drinking throughout the day, food particles and plaque are constantly building up. For optimal oral health, it’s essential to brush your teeth twice a day, but also to floss daily and use mouthwash to help clean those hard-to-reach areas. ',
      },
      {
        title: 'Sugar Causes Cavities',
        content:
          'While sugar does play a significant role in the development of cavities, it’s not the sugar itself but the bacteria in your mouth that feed on it. These bacteria produce acids that erode tooth enamel, leading to cavities. Therefore, it’s not just the amount of sugar you consume but how often you expose your teeth to sugary foods and drinks. Maintaining good oral hygiene by brushing and flossing can help reduce the risk of cavities, even if you enjoy an occasional sweet treat.',
      },
    ],
  },
  {
    id: 8,
    title: 'Top 5 Common Dental Myths Debunked',
    date: '02.09.2024',
    description:
      'Address and clarify common misconceptions about dental care, such as the effectiveness of brushing alone or the impact of sugar on teeth.',
    image: '/blog/blog2.png',
    sections: [
      {
        title: 'You Only Need to Brush Your Teeth Twice a Day',
        content:
          'Brushing your teeth twice a day is a good start, but it’s not always enough. The American Dental Association (ADA) recommends brushing your teeth for two minutes each time and using fluoride toothpaste. However, if you’re eating and drinking throughout the day, food particles and plaque are constantly building up. For optimal oral health, it’s essential to brush your teeth twice a day, but also to floss daily and use mouthwash to help clean those hard-to-reach areas. ',
      },
      {
        title: 'Sugar Causes Cavities',
        content:
          'While sugar does play a significant role in the development of cavities, it’s not the sugar itself but the bacteria in your mouth that feed on it. These bacteria produce acids that erode tooth enamel, leading to cavities. Therefore, it’s not just the amount of sugar you consume but how often you expose your teeth to sugary foods and drinks. Maintaining good oral hygiene by brushing and flossing can help reduce the risk of cavities, even if you enjoy an occasional sweet treat.',
      },
    ],
  },
  {
    id: 9,
    title: 'A Guide to Preventing Dental Emergencies',
    date: '02.09.2024',
    description:
      'Offer tips on how to prevent common dental issues like chipped teeth, gum infections, and toothaches, and what to do if an emergency occurs.',
    image: '/blog/blog1.png',
    sections: [
      {
        title: 'You Only Need to Brush Your Teeth Twice a Day',
        content:
          'Brushing your teeth twice a day is a good start, but it’s not always enough. The American Dental Association (ADA) recommends brushing your teeth for two minutes each time and using fluoride toothpaste. However, if you’re eating and drinking throughout the day, food particles and plaque are constantly building up. For optimal oral health, it’s essential to brush your teeth twice a day, but also to floss daily and use mouthwash to help clean those hard-to-reach areas. ',
      },
      {
        title: 'Sugar Causes Cavities',
        content:
          'While sugar does play a significant role in the development of cavities, it’s not the sugar itself but the bacteria in your mouth that feed on it. These bacteria produce acids that erode tooth enamel, leading to cavities. Therefore, it’s not just the amount of sugar you consume but how often you expose your teeth to sugary foods and drinks. Maintaining good oral hygiene by brushing and flossing can help reduce the risk of cavities, even if you enjoy an occasional sweet treat.',
      },
    ],
  },
  {
    id: 10,
    title: 'The Connection Between Oral Health and Overall Wellness',
    date: '02.09.2024',
    description:
      'Explore how maintaining good oral hygiene can impact overall health, including cardiovascular health, diabetes, and even mental health.',
    image: '/blog/blog1.png',
    sections: [
      {
        title: 'You Only Need to Brush Your Teeth Twice a Day',
        content:
          'Brushing your teeth twice a day is a good start, but it’s not always enough. The American Dental Association (ADA) recommends brushing your teeth for two minutes each time and using fluoride toothpaste. However, if you’re eating and drinking throughout the day, food particles and plaque are constantly building up. For optimal oral health, it’s essential to brush your teeth twice a day, but also to floss daily and use mouthwash to help clean those hard-to-reach areas. ',
      },
      {
        title: 'Sugar Causes Cavities',
        content:
          'While sugar does play a significant role in the development of cavities, it’s not the sugar itself but the bacteria in your mouth that feed on it. These bacteria produce acids that erode tooth enamel, leading to cavities. Therefore, it’s not just the amount of sugar you consume but how often you expose your teeth to sugary foods and drinks. Maintaining good oral hygiene by brushing and flossing can help reduce the risk of cavities, even if you enjoy an occasional sweet treat.',
      },
    ],
  },
  {
    id: 11,
    title: 'Top 5 Common Dental Myths Debunked',
    date: '02.09.2024',
    description:
      'Address and clarify common misconceptions about dental care, such as the effectiveness of brushing alone or the impact of sugar on teeth.',
    image: '/blog/blog2.png',
    sections: [
      {
        title: 'You Only Need to Brush Your Teeth Twice a Day',
        content:
          'Brushing your teeth twice a day is a good start, but it’s not always enough. The American Dental Association (ADA) recommends brushing your teeth for two minutes each time and using fluoride toothpaste. However, if you’re eating and drinking throughout the day, food particles and plaque are constantly building up. For optimal oral health, it’s essential to brush your teeth twice a day, but also to floss daily and use mouthwash to help clean those hard-to-reach areas. ',
      },
      {
        title: 'Sugar Causes Cavities',
        content:
          'While sugar does play a significant role in the development of cavities, it’s not the sugar itself but the bacteria in your mouth that feed on it. These bacteria produce acids that erode tooth enamel, leading to cavities. Therefore, it’s not just the amount of sugar you consume but how often you expose your teeth to sugary foods and drinks. Maintaining good oral hygiene by brushing and flossing can help reduce the risk of cavities, even if you enjoy an occasional sweet treat.',
      },
    ],
  },
  {
    id: 12,
    title: 'A Guide to Preventing Dental Emergencies',
    date: '02.09.2024',
    description:
      'Offer tips on how to prevent common dental issues like chipped teeth, gum infections, and toothaches, and what to do if an emergency occurs.',
    image: '/blog/blog1.png',
    sections: [
      {
        title: 'You Only Need to Brush Your Teeth Twice a Day',
        content:
          'Brushing your teeth twice a day is a good start, but it’s not always enough. The American Dental Association (ADA) recommends brushing your teeth for two minutes each time and using fluoride toothpaste. However, if you’re eating and drinking throughout the day, food particles and plaque are constantly building up. For optimal oral health, it’s essential to brush your teeth twice a day, but also to floss daily and use mouthwash to help clean those hard-to-reach areas. ',
      },
      {
        title: 'Sugar Causes Cavities',
        content:
          'While sugar does play a significant role in the development of cavities, it’s not the sugar itself but the bacteria in your mouth that feed on it. These bacteria produce acids that erode tooth enamel, leading to cavities. Therefore, it’s not just the amount of sugar you consume but how often you expose your teeth to sugary foods and drinks. Maintaining good oral hygiene by brushing and flossing can help reduce the risk of cavities, even if you enjoy an occasional sweet treat.',
      },
    ],
  },
];

declare global {
  interface Window {
    publicURL: string;
  }
}

const app = createApp(App);
const pinia = createPinia();

let loc = window.location.protocol + '//' + window.location.hostname;

if (loc.indexOf('localhost') > -1) {
  loc = 'http://localhost:8080';
}

window.publicURL = loc;

Directives(app);

app.use(pinia);
app.use(router);
app.mount('#app');
